
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Image from "next/image";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import illustration from "../../public/assets/404.webp";
import { PageLayout } from "../layouts/page-layout/PageLayout";
export default function NotFoundPage() {
    const { t } = useTranslation();
    return (<PageLayout>
      <div className={"mx-auto my-[10%] max-w-3xl px-4 text-center"}>
        <Image src={illustration} alt={"Not Found Image"}/>
        <h1 className={"typo-h1 mt-8"}>{t("pageNotFoundTitle")}</h1>
        <p className={"typo-lg mt-4 lg:px-28"}>
          {t("pageNotFoundDescription")}{" "}
          <Link className={"text-brand-75 typo-lg--link"} href={"/"}>
            {t("here")}
          </Link>
          .
        </p>
      </div>
    </PageLayout>);
}

    async function __Next_Translate__getStaticProps__194a75ead8b__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194a75ead8b__ as getStaticProps }
  